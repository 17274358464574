import { SettingsSystemDaydream } from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import { StatusRecordService } from "../../../services/status/StatusRecordService";
import { System } from "../../../services/system/models/System";
import { SystemAlert } from "../../../services/system/models/SystemAlert";
import styles from "../styles/Components.css";
import SystemStatusModal from "./SystemStatusModal";

interface Props {}

interface StatusObjectProps {
  status: string;
  className: string;
  onClick?: (event: any) => void;
  onMouseOver?: (event: any) => void;
  onMouseOut?: (event: any) => void;
}

interface SmallStatusIndicatorProps {
  system: System;
  onMouseOver?: (event: any) => void;
  onMouseOut?: (event: any) => void;
}

interface DetailedStatusPopupProps {
  system?: System;
  ancorEl?: Element;
  open: boolean;
  onClose: () => void;
}

const SystemStatusIndicator: FunctionComponent<Props> = (props) => {
  const [pulse, setPulse] = useState(false);
  const [secondaryPulse, setSecondaryPulse] = useState(false);
  const [state, actions] = AppStore.systems.use();
  const [system, setSystem] = useState<System | undefined>();
  const [open, setOpen] = useState(false);
  const [online, setOnline] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [ancorEl, setAncorEl] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);

  const handleOpenWarning = (event) => {
    setAncorEl(event.currentTarget);
    setOpenWarning(true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
    setAncorEl(null);
  };

  const handleOpenStatusDetail = (event) => {
    setOpen(true);
  };

  const handleCloseStatusDetail = () => {
    setOpen(false);
  };

  const handlePulse = () => {
    setPulse(true);
    setSecondaryPulse(true);
    setTimeout(() => setPulse(false), 600);
    setTimeout(() => setSecondaryPulse(false), 1000);
  };

  const handleStatus = (system: System) => {
    if (state.highlighted) {
      const uploadDifference = Date.now() - Date.parse(system.lastUploaded);

      setOnline(state.highlighted.isOnline());

      if (uploadDifference < 0)
        setShowWarning(true);
      else if (system.alerts.length > 0)
        setShowWarning(true);
    }
  };

  useEffect(() => {
    const highlighted = state.highlighted;

    setShowWarning(false);

    if (!system || !highlighted) return;

    handleStatus(highlighted);

    if (system.id == highlighted.id) handlePulse();
  }, [state.highlighted?.lastUploaded]);

  useEffect(() => {
    if (!state.highlighted) return;

    setSystem(state.highlighted);
    handleStatus(state.highlighted);
  }, [state.highlighted]);

  return (
    <div className={styles.systemStatusSummaryContainer}>
      <div
        className={`${styles.systemStatusOutline} ${
          secondaryPulse ? styles.delayPulse : ""
        }`}
      >
        <div
          className={`${styles.systemStatusInternalOutline} ${
            secondaryPulse ? styles.delayPulse : ""
          }`}
        >
          <StatusObject
            onClick={handleOpenStatusDetail}
            status={(online) ? "online" : "offline"}
            className={`${styles.systemStatus} 
          ${pulse ? styles.pulse : ""}`}
          >
            <Typography className={styles.systemStatusTitle} variant="body1">
              { (online) ? "Online" : "Offline"}
            </Typography>
          </StatusObject>
        </div>
      </div>
      {showWarning && (
        <div className={styles.systemWarningContainer}>
          <img
            src="./icons/alert.png"
            width="25"
            height="25"
            onClick={handleOpenStatusDetail}
          />
        </div>
      )}
      {/* <Menu
        anchorEl={ancorEl}
        open={openWarning}
        onClose={handleCloseWarning}
        onClick={handleCloseWarning}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Typography variant="subtitle1" color="orange">
            {warning}
          </Typography>
        </MenuItem>
      </Menu> */}
      <SystemStatusModal onDismiss={handleCloseStatusDetail} show={open} />
    </div>
  );
};

const StatusObject: FunctionComponent<StatusObjectProps> = (props) => {
  return <div {...props} />;
};

const DetailedStatusPopup: FunctionComponent<DetailedStatusPopupProps> = (
  props
) => {
  const [warningCount, setWarningCount] = useState(0);

  const renderAlert = (alert: SystemAlert) => {
    return <Typography variant="body2">{alert.value}</Typography>;
  };

  useEffect(() => {
    if (props.system)
      StatusRecordService.getRecentBySystemId(props.system.id).then((record) =>
        setWarningCount(record.alerts.length)
      );
  }, [props.system]);

  return (
    <Menu
      anchorEl={props.ancorEl}
      open={props.open}
      onClose={props.onClose}
      // onClick={handleCloseStatusDetail}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    >
      <div className={styles.detailedStatusContainer}>
        <div className={styles.detailedStatusHeader}>
          <Typography fontWeight="bold" variant="body2">
            Detailed System Status
          </Typography>
        </div>
        <div className={styles.detailedStatusBody}>
          {props.system?.alerts.map(renderAlert)}
        </div>
      </div>
    </Menu>
  );
};

const SmallStatusIndicator: FunctionComponent<SmallStatusIndicatorProps> = (
  props
) => {
  const [state, actions] = AppStore.systems.use();
  const [pulse, setPulse] = useState(false);
  const [online, setOnline] = useState(false);
  const [lastUploaded, setLastUploaded] = useState(0);

  const handlePulse = () => {
    setPulse(true);
    setTimeout(() => setPulse(false), 600);
  };

  const handleStatus = (system: System) => {
    
    if (system) {
      setOnline(system.isOnline());
    }
  };

  useEffect(() => {
    const system = state.collection.find(
      (system) => system.id == props.system.id
    );
    // if (system)
    // {
    //   setLastUploaded(Date.parse(system.lastUploaded));
    // }
    // else
    // {
      setLastUploaded(Date.parse(props.system.lastUploaded));
    // }
    
    handleStatus(props.system)
  }, [props.system]);

  useEffect(() => {
    const system = state.collection.find(
      (system) => system.id == props.system.id
    );

    if (system) {
      const date = Date.parse(system.lastUploaded);

      if (lastUploaded != date) {
        // setLastUploaded(Date.parse(system.lastUploaded));
        handlePulse();
        handleStatus(system);
      }

      setLastUploaded(date);
    }
  }, [state.collection]);

  const statusRender = (props) => (
    <div {...props} className={`${styles.systemStatusSmall} 
    ${pulse ? styles.pulse : ""}`} />
  );

    return <div>{statusRender({ status: (online) ? "online" : "offline" })}</div>;
};

export { SystemStatusIndicator, StatusObject, SmallStatusIndicator };
